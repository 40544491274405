// React
import React from 'react'
import PropTypes from 'prop-types'

// Gatsby
import {
    Link,
} from "gatsby"

// Images
import Img from "gatsby-image"
import imgLogo from "../../content/assets/site-icon.png"

// Styles
import "../styles/components/PostCard.scss"

// Post card component
// (is used on blog-index and tag-index pages)
const PostCard = ({
    post
}) => {

    // Post title
    const title = post.frontmatter.title || post.fields.slug;

    // Post thumbnail
    let featuredImgFluid = post.frontmatter.image?.childImageSharp?.fluid;

    // Render
    return (
        <article
            className="post-card"
            key={post.fields.slug}
        >

            {/* Post thumbnail image file */}
            {/* {featuredImgFluid &&
                <Link
                    className="thumbnail"
                    to={post.fields.slug}
                >
                    <Img
                        fluid={featuredImgFluid}
                    />
                </Link>
            } */}

            {/* Post "thumbnail" generated */}
            {/* {
                !featuredImgFluid &&
                <Link
                    className="thumbnail thumbnail-none"
                    to={post.fields.slug}
                >
                    <div>
                        {title}
                    </div>
                </Link>
            } */}

            <main>

                <header>

                    {/* Post category */}
                    <Link
                        className="category"
                        to={post.fields.slug}
                    >
                        {post.frontmatter.category}
                    </Link>

                    {/* Post title */}
                    <h4>
                        <Link
                            to={post.fields.slug}
                        >
                            {title}
                        </Link>
                    </h4>

                </header>

                <section>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description || post.excerpt,
                        }}
                    />
                </section>

            </main>

            <footer>

                {/* Post tags */}
                <div className="tags">
                    {post.frontmatter.tags.map((tag) => {
                        return (
                            <Link to={`/tags/${tag}`} key={tag}>
                                {tag}
                            </Link>
                        )
                    })}
                </div>

                {/*  Publication info */}
                <div className="publication">

                    <Link
                        to="/blog"
                        className="image"
                    >
                        <img
                            className="branding-logo"
                            src={imgLogo}
                            alt="Brand logo"
                        />
                    </Link>

                    <div className="datebox">

                        <Link
                            to="/blog"
                            className="author"
                        >
                            Ben from GameDevMix
                        </Link>

                        {/* Post date */}
                        <div className="date">
                            {post.frontmatter.date}
                        </div>
                        
                        {/* TODO: Reading time */}
                        {/* &nbsp;&middot;&nbsp;
                        x min read */}
                        {/* {` • ${formatReadingTime(post.timeToRead)}`} */}

                    </div>
                </div>

            </footer>

        </article>
    )
}

// Prop types
PostCard.propTypes = {
    post: PropTypes.object.isRequired,
}

// Export
export default PostCard
